import React from 'react';
import { useStaticQuery } from "gatsby"
import LegacyContainer from '../components/legacy-container';
import SEO from '../components/SEO';
import PageTitle from '../components/modular/PageTitle/PageTitle';
import LinkBlocks from '../components/modular/LinkBlocks/LinkBlocks';
import ParagraphText from '../components/modular/ParagraphText/ParagraphText';
import FullWidthImage from '../components/modular/FullWidthImage/FullWidthImage';

const Music = () => {
  const data = useStaticQuery(graphql`
  {
    servicePages: allFile(sort: {fields: childMarkdownRemark___frontmatter___menuOrder}, filter: {sourceInstanceName: {eq: "music"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            heading
            pageSlug
          }
        }
      }
    }
    serviceContent: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "music-page" } }}
        limit: 1
      ) {
        edges {
          node {
            frontmatter {
              heading,
              headlineImage,
              imageGallery {
                image,
                description
              },
              reviews {
                name,
                review
              },
              pressPackDownloadUrl,
              soundCloudEmbedLink,
              spotifyEmbedLink
            }
            html
          }
        }
      }
    
  }
  `)



  return (
    <LegacyContainer>
      <SEO pageTitle={data.serviceContent.edges[0].node.frontmatter.heading} />
      <div className="content-container">
        <div className="content">
          <PageTitle title={data.serviceContent.edges[0].node.frontmatter.heading} />
          <FullWidthImage image={data.serviceContent.edges[0].node.frontmatter.headlineImage} />
          <LinkBlocks base="music" links={data.servicePages.nodes.map(mapMenuData)} /><ParagraphText html={data.serviceContent.edges[0].node.html} />
        </div>
      </div>
    </LegacyContainer>
  );
}

export default Music

const mapMenuData = menuItem => {
  return {
    text: menuItem.childMarkdownRemark.frontmatter.heading,
    url: menuItem.childMarkdownRemark.frontmatter.pageSlug
  }
}